import { actionDescriptors } from "./actions";

const reducer = (state, action) => {
  switch (action.type) {
    case actionDescriptors.fetchReceipts:
      return {
        ...state,
        isReceiptsLoading: true
      };
    case actionDescriptors.fetchReceiptsSuccessful:
      return {
        ...state,
        receipts: action.payload,
        isReceiptsLoading: false,
      };
    case actionDescriptors.fetchReceiptsFailed:
      return {
        ...state,
        error: action.error,
        isReceiptsLoading: false
      };
    case actionDescriptors.fetchReceiptDetails:
      return {
        ...state,
        isReceiptDetailsLoading: true
      };
    case actionDescriptors.fetchReceiptDetailsSuccessful:
      return {
        ...state,
        receiptDetails: action.payload,
        isReceiptDetailsLoading: false
      };
    case actionDescriptors.fetchReceiptDetailsFailed:
      return {
        ...state,
        error: action.error,
        isReceiptDetailsLoading: false
      };
    default:
      throw new Error(`Unhandled action: '${action.type}'`);
  }
}

export default reducer;
