export const apiUrl = process.env.REACT_APP_URL
  ? process.env.REACT_APP_URL + "/api/v1"
  : "/api/v1";

export const HTTP_METHODS = {
  GET: "GET",
  POST: "POST",
  PATCH: "PATCH",
  PUT: "PUT",
};

export const routes = {
  HOME: '/',
}

export const DATE_FORMAT_STRINGS = {
  TIMESTAMP: 'MM-DD-YYYY hh:mm a',
  DATE: 'MM-DD-YYYY'
}
