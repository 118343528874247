import React, { useState, useEffect } from 'react'
import { Card, Button, InputNumber, Select, Typography, Row } from 'antd'
import TransctiontionModal from './TransactionModal'
import { User } from '../../types/index'
import { filterDuplicateUserAddresses } from '../../helpers/utils'

function TransactionCard(props: { users: [] }) {

  const { users } = props

  const usersList = users.map((user: User) => ({ label: `${user.commonName} - ${user.organization}`, value: user.userAddress }))
  const filteredDupUsersList = filterDuplicateUserAddresses(usersList)

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [amount, setAmount] = useState<number | undefined>()
  const [receiverAddress, setReceiverAddress] = useState<string | undefined>()
  const [receiverLabel, setReceiverLabel] = useState<string | undefined>()

  const showModal = () => {
    setOpenModal(true);
  };

  const handleChange = (value: any) => {
    setAmount(value);
  }
  const handleSelect = (value: any, option: any) => {
    setReceiverLabel(option.label)
    setReceiverAddress(value)
  }

  return (
    <>
      <TransctiontionModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        amount={amount}
        receiverAddress={receiverAddress}
        receiverLabel={receiverLabel}
      />
      <Typography.Title level={2}>Transfer</Typography.Title>
      <Card style={{ height: 200 }}>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
          <Row>
            <Select
              style={{ width: '20rem', marginBottom: '10px' }}
              placeholder={'Select Recipient'}
              showSearch
              size="large"
              onSelect={handleSelect}
              options={filteredDupUsersList}
              optionFilterProp="value"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </Row>
          <Row>
            <InputNumber
              style={{ width: '20rem', marginBottom: '10px' }}
              controls={false}
              size={"large"}
              min={0.00}
              precision={2}
              defaultValue={amount}
              onChange={(e) => handleChange(e)}
              value={amount}
            />
          </Row>
          <Row>
            <Button type="primary" size={'large'} shape="round" disabled={(amount === 0 || amount === null || amount === undefined || receiverAddress === undefined) && true} onClick={showModal}>
              Transfer
            </Button>
          </Row>
        </div>
      </Card>
    </>
  )
}

export default TransactionCard
