import React, { createContext, useContext, useReducer } from "react";
import reducer from "./reducer";

const TokenStateContext = createContext();
const TokenDispatchContext = createContext();

const TokensProvider = ({ children }) => {
  const initialState = {
    token: null,
    isMintTokenSubmitting: false,
    balance: null,
    balanceLoading: false,
    tokenOwnership: null,
    isOwnershipTokenTransferring: false,
    tokenDestroyed: null,
    isTokenBeingDestroyed: false,
    tokenRedemption: null,
    isTokenRedeeming: false,
    reserve: null,
    isTokenReserveLoading: false,
    error: undefined,
    success: false,
    message: null,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <TokenStateContext.Provider value={state}>
      <TokenDispatchContext.Provider value={dispatch}>
        {children}
      </TokenDispatchContext.Provider>
    </TokenStateContext.Provider>
  );
};

const useTokenState = () => {
  const context = useContext(TokenStateContext);
  if (context === undefined) {
    throw new Error(
      `'useTokenState' must be used within a TokensProvider`
    );
  }
  return context;
};

const useTokenDispatch = () => {
  const context = useContext(TokenDispatchContext);
  if (context === undefined) {
    throw new Error(
      `'useTokenDispatch' must be used within a TokensProvider`
    );
  }
  return context;
};

const useTokenUnit = () => {
  return [useTokenState(), useTokenDispatch()];
};

export {
  useTokenDispatch,
  useTokenState,
  useTokenUnit,
  TokensProvider,
};
