import React, { useState } from "react";
import { Button, Modal, Row, Col, DatePicker, Typography } from "antd";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"
import { useMetadataDispatch, useMetadataState } from "../contexts/metadata";
import { actions } from "../contexts/metadata/actions";

dayjs.extend(utc)
const { RangePicker } = DatePicker;
type RangeValue = [Dayjs | null, Dayjs | null] | null;

function CustomQueryModal(props: { openModal: boolean; setOpenModal: any }) {
  const { openModal, setOpenModal } = props;
  const [selectedDates, setSelectedDates] = useState<RangeValue>();
  const dispatch = useMetadataDispatch();

  const { queriedMetadata, isQueryMetadataLoading } = useMetadataState();

  const handleCancel = () => {
    setOpenModal(false);
    actions.clearQueryMetadata(dispatch);
  };

  const handleSubmit = () => {
    if (selectedDates) {
      const unixStart = selectedDates[0]?.utc().startOf('D').unix()
      const unixEnd = selectedDates[1]?.utc().endOf('D').unix()
      try {
        actions.fetchMetadata(
          dispatch,
          unixStart,
          unixEnd,
          "queryMetadata"
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Modal
        title="Custom Query"
        open={openModal}
        onCancel={handleCancel}
        footer={[
          <Button
            key="back"
            onClick={handleCancel}
            disabled={isQueryMetadataLoading}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            disabled={!selectedDates}
            onClick={handleSubmit}
            loading={isQueryMetadataLoading}
          >
            Get Data
          </Button>,
        ]}
      >
        <Row>
          <Col>
            <RangePicker
              allowClear
              onChange={(date) => {
                setSelectedDates(date);
              }}
            />

            {queriedMetadata && (
              <>
                <Typography.Title level={5} style={{ margin: "20px 10px" }}>
                  Transaction Count: {queriedMetadata.totalTransactionCount}
                </Typography.Title>

                <Typography.Title level={5} style={{ margin: "10px 10px" }}>
                  Transaction Value: {queriedMetadata.totalValueExchanged/100}
                </Typography.Title>
              </>
            )}
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default CustomQueryModal;
