import React from 'react'
import { Button, Modal, Row, Col, Typography } from 'antd';
import { actions } from '../../contexts/tokens/actions'
import { useTokenDispatch, useTokenState } from '../../contexts/tokens';

function TransactionModal(props: {
  openModal: boolean,
  setOpenModal: any,
  amount: number | undefined,
  receiverAddress: string | undefined,
  receiverLabel: string | undefined,
}) {

  const { openModal, setOpenModal, amount, receiverAddress, receiverLabel } = props

  const {
    tokenOwnership,
    isOwnershipTokenTransferring
  } = useTokenState()

  const dispatch = useTokenDispatch()

  const handleTransfer = async () => {
    try {
      if (!(amount === (null || undefined || 0)) || !(receiverAddress === (null || undefined))) {
        await actions.transferTokenOwnership(dispatch, {
          to: receiverAddress,
          value: amount !== undefined ? amount * 100 : 0
        })
        setOpenModal(false)
      }
    } catch (error) {
      console.log(error)
    }
  };

  const handleCancel = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Modal
        open={openModal}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={isOwnershipTokenTransferring} onClick={handleTransfer}>
            Submit
          </Button>,
        ]}
      >
        <Row>
          <Typography.Title level={5}>{`Are you sure you want to transfer:`}</Typography.Title>
        </Row>
        <Row>
          <Col offset={9}>
            <Typography.Title level={3}>{`${amount} STRATS`}</Typography.Title>
          </Col>
        </Row>
        <Row>
          <Col>
            <Typography.Title level={3}>{`To:`}</Typography.Title>
          </Col>
        </Row>
        <Row>          
          <Col offset={0}>
            <Typography.Text strong >{`Name:`}</Typography.Text>
          </Col>
          <Col offset={4}>
            <Typography.Text >{`${receiverLabel}`}</Typography.Text>
          </Col>
        </Row>
        <Row>
          <Col offset={2}>
            {/* <Typography.Title level={4}>Name:</Typography.Title> */}
          </Col>
          {/* <Col offset={2}>
          </Col> */}
        </Row>
        <Row>
          <Col offset={0}>
            <Typography.Text strong>Address:</Typography.Text>
          </Col>
          <Col offset={3}>
            <Typography.Text>{`${receiverAddress}`}</Typography.Text>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default TransactionModal
