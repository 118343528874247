import React, { useEffect } from "react";
import { Col, Row, Card, Typography } from "antd";
import { useTokenState, useTokenDispatch } from "../contexts/tokens";
import { actions } from "../contexts/tokens/actions";
import { User } from "../types/index";

function TokenBalance(props: { user: User }) {
  const { user } = props;

  const {
    token,
    tokenOwnership,
    tokenRedemption,
    tokenDestroyed,
    balance,
    balanceLoading,
  } = useTokenState();

  const dispatch = useTokenDispatch();

  useEffect(() => {
    try {
      actions.fetchBalance(dispatch);
    } catch (error) {
      console.log(error);
    }
  }, [
    dispatch,
    balance,
    token,
    tokenOwnership,
    tokenRedemption,
    tokenDestroyed,
  ]);

  return (
    <>
      <Col offset={2} span={10} style={{marginTop: '64px'}}>
        <Card
          size="small"
          title={user.admin ? "Reserve Balance" : "Balance"}
          style={{ marginTop: 20 }}
          loading={balanceLoading}
        >
          <Typography.Title level={3} style={{ margin: 0 }}>{`${
            (parseFloat(balance)/100).toLocaleString()} STRATS`}</Typography.Title>
        </Card>
      </Col>
    </>
  );
}

export default TokenBalance;
