import React, { useEffect } from "react";
import MenuWrapper from "./MenuWrapper";
import { Spin, Card, Alert } from "antd";
import {
  useAuthenticateState,
  useAuthenticateDispatch,
} from "../contexts/authentication/index";
import { TokensProvider } from "../contexts/tokens";
import { UsersProvider } from "../contexts/users";
import { ReceiptsProvider } from "../contexts/receipts";
import { MetadataProvider } from "../contexts/metadata";

/**
 * The provides user data and authentication for the rest of app
 */
function Scaffold() {
  const {
    isAuthenticated,
    isCheckingAuthentication,
    hasChecked,
    user,
    loginUrl,
  } = useAuthenticateState();

  useEffect(() => {
    if (hasChecked && !isAuthenticated && loginUrl !== undefined) {
      window.location.href = loginUrl;
    }
  }, [isAuthenticated, hasChecked, loginUrl]);

  return (
    <>
      <Spin spinning={isCheckingAuthentication} delay={0} size="large">
        {hasChecked && isAuthenticated ? (
          <UsersProvider>
            <TokensProvider>
              <ReceiptsProvider>
                <MetadataProvider>
                  <MenuWrapper user={user} />
                </MetadataProvider>
              </ReceiptsProvider>
            </TokensProvider>
          </UsersProvider>
        ) : (
          <Card>
            <Alert
              closable={false}
              type="error"
              description="An error occured when attempting to verify user
              credentials. The backend may be down, or the user
              does not have valid Mercata credentials.
              Contact a System Administrator or try again later."
            />
          </Card>
        )}
      </Spin>
    </>
  );
}
export default Scaffold;
