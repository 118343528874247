import RestStatus from "http-status-codes";
import { apiUrl, HTTP_METHODS } from "../../helpers/constants";

const actionDescriptors = {
  resetMessage: "reset_message",
  setMessage: "set_message",
  mintToken: "mint_token",
  mintTokenSuccessful: "mint_token_successful",
  mintTokenFailed: "mint_token_failed",
  fetchBalance: "fetch_balance",
  fetchBalanceSuccessful: "fetch_balance_successful",
  fetchBalanceFailed: "fetch_balance_failed",
  transferTokenOwnership: "transfer_token_ownership",
  transferTokenOwnershipSuccessful: "transfer_token_ownership_successful",
  transferTokenOwnershipFailed: "transfer_token_ownership_failed",
  destroyToken: "destroy_token",
  destroyTokenSuccessful: "destroy_token_successful",
  destroyTokenFailed: "destroy_token_failed",
  redeemToken: "redeem_token",
  redeemTokenSuccessful: "redeem_token_successful",
  redeemTokenFailed: "redeem_token_failed",
  fetchTokenReserve: "fetch_token_reserve",
  fetchTokenReserveSuccessful: "fetch_token_reserve_successful",
  fetchTokenReserveFailed: "fetch_token_reserve_failed",
};

const actions = {
  resetMessage: (dispatch) => {
    dispatch({ type: actionDescriptors.resetMessage });
  },

  setMessage: (dispatch, message, success = false) => {
    dispatch({ type: actionDescriptors.setMessage, message, success });
  },

  mintToken: async (dispatch, payload) => {
    dispatch({ type: actionDescriptors.mintToken });
    try {
      const response = await fetch(`${apiUrl}/token`, {
        method: HTTP_METHODS.POST,
        credentials: "same-origin",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      const body = await response.json();

      if (response.status === RestStatus.OK) {
        dispatch({
          type: actionDescriptors.mintTokenSuccessful,
          payload: body.data,
        });
        actions.setMessage(dispatch, "Token minted successfully", true)
        return true;
      }

      dispatch({ type: actionDescriptors.mintTokenFailed, error: 'Error while minting Token' });
      actions.setMessage(dispatch, "Error while minting Token")
      return false;

    } catch (err) {
      dispatch({ type: actionDescriptors.mintTokenFailed, error: "Error while creating Token" });
      actions.setMessage(dispatch, "Error while creating Token")
    }
  },
  fetchBalance: async (dispatch) => {
    dispatch({ type: actionDescriptors.fetchBalance });

    try {
      const response = await fetch(`${apiUrl}/token`, {
        method: HTTP_METHODS.GET,
        credentials: "same-origin",
      });

      const body = await response.json();
      if (response.status === RestStatus.OK) {
        dispatch({
          type: actionDescriptors.fetchBalanceSuccessful,
          payload: body.data,
        });
        return;
      }
      dispatch({ type: actionDescriptors.fetchBalanceFailed, payload: 'token balance request failed' });
      return;
    } catch (err) {
      dispatch({ type: actionDescriptors.fetchBalanceFailed, payload: 'token balance request failed' });
    }
  },

  transferTokenOwnership: async (dispatch, payload) => {
    dispatch({ type: actionDescriptors.transferTokenOwnership });

    try {
      const response = await fetch(`${apiUrl}/token/transfer`, {
        method: HTTP_METHODS.POST,
        credentials: "same-origin",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      const body = await response.json();

      if (response.status === RestStatus.OK) {
        dispatch({
          type: actionDescriptors.transferTokenOwnershipSuccessful,
          payload: body.data,
        });
        actions.setMessage(dispatch, "Product has been transferred", true);
        return true;
      }

      dispatch({ type: actionDescriptors.transferTokenOwnershipFailed, error: 'Error while transfer ownership token' });
      actions.setMessage(dispatch, "Error while transfer ownership token")
      return false;

    } catch (err) {
      dispatch({ type: actionDescriptors.transferTokenOwnershipFailed, error: "Error while transfer ownership token" });
      actions.setMessage(dispatch, "Error while transfer ownership token")
    }
  },

  destroyToken: async (dispatch, payload) => {
    dispatch({ type: actionDescriptors.destroyToken });

    try {
      const response = await fetch(`${apiUrl}/token/destroy`, {
        method: HTTP_METHODS.POST,
        credentials: "same-origin",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      const body = await response.json();

      if (response.status === RestStatus.OK) {
        dispatch({
          type: actionDescriptors.destroyTokenSuccessful,
          payload: body.data,
        });
        actions.setMessage(dispatch, "Product has been destroyed", true);
        return true;
      }

      dispatch({ type: actionDescriptors.destroyTokenFailed, error: "Error while destroying token" });
      actions.setMessage(dispatch, "Error while destroying token")
      return false;

    } catch (err) {
      dispatch({ type: actionDescriptors.destroyTokenFailed, error: "Error while destroying token" });
      actions.setMessage(dispatch, "Error while destroying token")
    }
  },
  redeemToken: async (dispatch, payload) => {
    dispatch({ type: actionDescriptors.redeemToken });

    try {
      const response = await fetch(`${apiUrl}/token/exchange`, {
        method: HTTP_METHODS.POST,
        credentials: "same-origin",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      const body = await response.json();

      if (response.status === RestStatus.OK) {
        dispatch({
          type: actionDescriptors.redeemTokenSuccessful,
          payload: body.data,
        });
        actions.setMessage(dispatch, "Token has been redeemed", true);
        return true;
      }

      dispatch({ type: actionDescriptors.redeemTokenFailed, error: 'Error while redeeming Token' });
      actions.setMessage(dispatch, "Error while redeeming Token")
      return false;

    } catch (err) {
      dispatch({ type: actionDescriptors.redeemTokenFailed, error: "Error while redeeming Token" });
      actions.setMessage(dispatch, "Error while redeeming Token")
    }
  },
  fetchTokenReserve: async (dispatch) => {
    dispatch({ type: actionDescriptors.fetchTokenReserve });

    try {
      const response = await fetch(`${apiUrl}/token/reserve`, {
        method: HTTP_METHODS.GET,
      });

      const body = await response.json();

      if (response.status === RestStatus.OK) {
        dispatch({
          type: actionDescriptors.fetchTokenReserveSuccessful,
          payload: body.data,
        });
        return;
      }
      dispatch({ type: actionDescriptors.fetchTokenReserveFailed, payload: 'token reserve request failed' });
    } catch (err) {
      dispatch({ type: actionDescriptors.fetchTokenReserveFailed, payload: 'token reserve request failed' });
    }
  }
};

export { actionDescriptors, actions };
