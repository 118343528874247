import { actionDescriptors } from "./actions";

const reducer = (state, action) => {
  switch (action.type) {
    case actionDescriptors.fetchMetadata:
      return {
        ...state,
        isMetadataLoading: true
      };
    case actionDescriptors.fetchMetadataSuccessful:
      return {
        ...state,
        metadata: action.payload,
        isMetadataLoading: false
      };
    case actionDescriptors.fetchMetadataFailed:
      return {
        ...state,
        error: action.error,
        isMetadataLoading: false
      };
    case actionDescriptors.queryMetadata:
      return {
        ...state,
        isQueryMetadataLoading: true
      };
    case actionDescriptors.queryMetadataSuccessful:
      return {
        ...state,
        queriedMetadata: action.payload,
        isQueryMetadataLoading: false
      };
    case actionDescriptors.queryMetadataFailed:
      return {
        ...state,
        error: action.error,
        isQueryMetadataLoading: false
      };
    case actionDescriptors.clearQueryMetadata:
      return {
        ...state,
        queriedMetadata: null
      };
    default:
      throw new Error(`Unhandled action: '${action.type}'`);
  }
};

export default reducer;
