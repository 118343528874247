import RestStatus from "http-status-codes";
import { apiUrl, HTTP_METHODS } from "../../helpers/constants";

const actionDescriptors = {
  fetchReceipts: "fetch_receipts",
  fetchReceiptsSuccessful: "fetch_receipts_successful",
  fetchReceiptsFailed: "fetch_receipts_failed",
  fetchReceiptDetails: "fetch_receipt_details",
  fetchReceiptDetailsSuccessful: "fetch_receipt_details_successful",
  fetchReceiptDetailsFailed: "fetch_receipt_details_failed",
}

const actions = {
  fetchReceipts: async (dispatch, limit = 25, offset = 0) => {
    dispatch({ type: actionDescriptors.fetchReceipts });

    try {
      // TODO: limits and offsets, duh
      const response = await fetch(`${apiUrl}/receipt`, {
        method: HTTP_METHODS.GET,
      });

      const body = await response.json();

      if (response.status === RestStatus.OK) {
        dispatch({
          type: actionDescriptors.fetchReceiptsSuccessful,
          payload: body.data,
        });
        return;
      }
      dispatch({ type: actionDescriptors.fetchReceiptsFailed, error: undefined });
    } catch (err) {
      dispatch({ type: actionDescriptors.fetchReceiptsFailed, error: err });
    }
  },
  fetchReceiptDetails: async (dispatch, id, chainId) => {
    dispatch({ type: actionDescriptors.fetchReceiptDetails });

    try {
      const response = await fetch(`${apiUrl}/receipt/${id}`, {
        method: HTTP_METHODS.GET
      });

      const body = await response.json();

      if (response.status === RestStatus.OK) {
        dispatch({
          type: actionDescriptors.fetchReceiptDetailsSuccessful,
          payload: body.data,
        });

        return true;
      }

      dispatch({ type: actionDescriptors.fetchReceiptDetailsFailed, error: 'Error while fetching receipt details' });
      return false;

    } catch (err) {
      dispatch({ type: actionDescriptors.fetchReceiptDetailsFailed, error: "Error while fetching receipt details" });
    }
  },
}

export { actionDescriptors, actions };
