import React from 'react';
import { ConfigProvider } from 'antd';
import Scaffold from './components/Scaffold';
import { BrowserRouter } from "react-router-dom";

const App: React.FC = () => (
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#3452FF',
        colorBgBase: '#E5E5E5',
      },
      components: {
        Card: {
          colorBgContainer: '#FAFAFA',
          borderRadius: 20,
        },
        Table: {
          colorBgContainer: '#FAFAFA',
        },
      },
    }}
  >
    <div className="App">
        <BrowserRouter>
          <Scaffold/>
        </BrowserRouter>
    </div> 
  </ConfigProvider>
);

export default App;
