export interface Error {
  status: number,
  message: string
}

export interface User {
  address: Address
  block_hash: string
  block_number: string
  block_timestamp: string
  certificateString: string
  chainId: string|undefined
  commonName: string
  country?: string
  isValid?: boolean
  organization?: string
  organizationalUnit?: string
  owner?: string
  parent?: string
  publicKey?: string
  record_id?: string
  transaction_hash?: string
  transaction_sender?: string
  userAddress?: Address
  admin?: boolean
}

// ----------- CIRRUS COLUMNS -----------------
export interface CirrusData {
  address: Address,
  chainId: string,
  record_id: string,
  block_hash: string,
  block_timestamp: string,
  block_number: string,
  transaction_hash: string,
  transaction_sender: string
}

export interface Receipt {
  id: number
  address: Address
  type: ReceiptType
  timestamp: number
  _from: string
  _to: string
  _value: string
}

export enum ReceiptType {
  TRANSFER = 0,
  REDEMPTION,
  PURCHASE,
  MINT,
  DESTROY
}

export const ReceiptTypeNames = {
  [ReceiptType.TRANSFER] : 'Transfer',
  [ReceiptType.REDEMPTION] : 'Redemption',
  [ReceiptType.PURCHASE] : 'Purchase',
  [ReceiptType.MINT]     : 'Mint',
  [ReceiptType.DESTROY]  : 'Destroy',
}

// ----------- ADDRESS TYPE SYNONYM ------------
export type Address = string
