import React, { createContext, useContext, useReducer } from "react";
import reducer from "./reducer";

const MetadataStateContext = createContext();
const MetadataContext = createContext();

const MetadataProvider = ({ children }) => {
  const initialState = {
    metadata: null,
    isMetadataLoading: false,
    queriedMetadata: null,
    isQueryMetadataLoading: false,
    error: undefined
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <MetadataStateContext.Provider value={state}>
      <MetadataContext.Provider value={dispatch}>
        {children}
      </MetadataContext.Provider>
    </MetadataStateContext.Provider>
  );
};

const useMetadataState = () => {
  const context = useContext(MetadataStateContext);
  if (context === undefined) {
    throw new Error(
      `'useMetadataState' must be used within a MetadataProvider`
    );
  }
  return context;
};

const useMetadataDispatch = () => {
  const context = useContext(MetadataContext);
  if (context === undefined) {
    throw new Error(
      `'useMetadataDispatch' must be used within a MetadataProvider`
    );
  }
  return context;
};

const useTokenUnit = () => {
  return [useMetadataState(), useMetadataDispatch()];
};

export {
  useMetadataDispatch,
  useMetadataState,
  useTokenUnit,
  MetadataProvider,
};
