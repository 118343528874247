import React, { useState, useEffect } from 'react'
import moment from "moment"
import { Table, Spin, Typography } from 'antd'
import { useReceiptsDispatch, useReceiptsState } from "../../contexts/receipts";
import { useTokenState } from '../../contexts/tokens'
import { actions } from "../../contexts/receipts/actions";
import { User, Receipt, ReceiptTypeNames } from "../../types/index";
import { DATE_FORMAT_STRINGS } from "../../helpers/constants" 

function TransactionTable(props: { users:[] }) {

  const { users } = props

  const {
    receipts,
    isReceiptsLoading,
  } = useReceiptsState();

  const {
    tokenOwnership,
    tokenRedemption,
    token,
    tokenDestroyed,
  } = useTokenState()

  const dispatch = useReceiptsDispatch();

  const data = receipts.map((r:Receipt) => {
    const displayName = (addr:string) => {
      const user:any = users.find((u:User) => u.userAddress == addr)
      if (user) { return `${user.commonName} - ${user.organization}` }
      else { 
        if (addr == '0000000000000000000000000000000000000000') { return 'RESERVE' }
        else { return addr }
      }
    }

    return {
      key: r.id,
      ...r,
      value: (parseInt(r._value)/100).toLocaleString(),
      to: displayName(r._to),
      from: displayName(r._from),
      type: ReceiptTypeNames[r.type],
      timestamp: moment.unix(r.timestamp).format(DATE_FORMAT_STRINGS.TIMESTAMP)
    }
  }).reverse()

  const columns = [
    { 
      title: 'Receipt Type',
      dataIndex: 'type',
    },
    {
      title: 'Date',
      dataIndex: 'timestamp',
    },
    {
      title: 'Sender',
      dataIndex: 'from'
    },
    {
      title: 'Recipient',
      dataIndex: 'to'
    },
    {
      title: 'Value',
      dataIndex: 'value'
    },
  ]

  useEffect(() => {
    actions.fetchReceipts(
      dispatch,
    );
  }, [dispatch, tokenOwnership, tokenRedemption, token, tokenDestroyed]);
  
  return (
    <>
      <Spin 
        spinning={isReceiptsLoading} 
        delay={500} 
        size='large'
    >
      <Typography.Title style={{ marginTop: 0 }}>Transaction History</Typography.Title>
      <Table
        columns={columns}
        dataSource={data}
      />
      </Spin>
    </>
  )
}

export default TransactionTable
