import RestStatus from "http-status-codes";
import { apiUrl, HTTP_METHODS } from "../../helpers/constants";

const actionDescriptors = {
  fetchMetadata: "fetch_metadata",
  fetchMetadataSuccessful: "fetch_metadata_successful",
  fetchMetadataFailed: "fetch_metadata_failed",
  queryMetadata: "query_metadata",
  queryMetadataSuccessful: "query_metadata_successful",
  queryMetadataFailed: "query_metadata_failed",
  clearQueryMetadata: "clear_metadata",
};

const actions = {
  clearQueryMetadata: async (dispatch) => {
    dispatch({ type: actionDescriptors.clearQueryMetadata })
  },
  fetchMetadata: async (dispatch, timestampMin, timestampMax, action = 'fetchMetadata') => {
    dispatch({ type: actionDescriptors[action] });

    const url = timestampMin && timestampMax ? `${apiUrl}/metadata?timestampMin=${timestampMin}&timestampMax=${timestampMax}` : `${apiUrl}/metadata`

    try {
      const response = await fetch(url, {
        method: HTTP_METHODS.GET,
        credentials: "same-origin",
      });

      const body = await response.json();
      if (response.status === RestStatus.OK) {
        dispatch({
          type: actionDescriptors[`${action}Successful`],
          payload: body.data
        });
        return;
      }
      dispatch({ type: actionDescriptors[`${action}Failed`], payload: 'metadata request failed' });
      return;
    } catch (err) {
      dispatch({ type: actionDescriptors[`${action}Failed`], payload: 'metadata request failed' });
    }
  }
};

export { actionDescriptors, actions };
