import React, { useEffect, useState } from "react";
import { Col, Card, Typography, Row, Button } from "antd";
import { useMetadataState, useMetadataDispatch } from "../contexts/metadata";
import { actions } from "../contexts/metadata/actions";
import CustomQueryModal from "./CustomQuery";

function Metadata() {
  const { metadata, isMetadataLoading } = useMetadataState();
  const [openModal, setOpenModal] = useState(false);

  const dispatch = useMetadataDispatch();

  useEffect(() => {
    try {
      actions.fetchMetadata(dispatch);
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  return (
    <>
      <CustomQueryModal openModal={openModal} setOpenModal={setOpenModal} />

      <Col offset={1} span={5}>
        <Typography.Title level={2}>Analytics</Typography.Title>
        <Card
          size="small"
          title={"Total Transaction Count"}
          style={{ marginTop: 20 }}
          loading={isMetadataLoading}
        >
          <Typography.Title level={3} style={{ margin: 0 }}>
            {metadata ? metadata.totalTransactionCount : 0}
          </Typography.Title>
        </Card>
      </Col>
      <Col span={5}>
        <div style={{ justifyContent: 'flex-end', display: 'flex', margin: '31px 0px 0px 0px' }}>
          <Button type="primary" onClick={() => setOpenModal(!openModal)}>
            Custom Query
          </Button>
        </div>
        <Card
          size="small"
          title={"Total Value Exchanged"}
          style={{ marginTop: 20 }}
          loading={isMetadataLoading}
        >
          <Typography.Title level={3} style={{ margin: 0 }}>
            {metadata ? metadata.totalValueExchanged/100 : 0}
          </Typography.Title>
        </Card>
      </Col>
    </>
  );
}

export default Metadata;
