import React, { useState, useEffect } from "react";
import logo from "../assets/Logo.png";
import { Routes, Route } from "react-router-dom";
import RestStatus from "http-status-codes";
import { Divider, Layout, Row, Space, Typography } from 'antd';
import { routes } from "../helpers/constants";
import Error from "./Error";
import HomeView from "../views/HomeView";
import { User } from "../types/index";
import UserMenu from "./UserMenu";
import TokenBalance from "./TokenBalance";
import Metadata from "./Metadata";

const { Header, Footer, Content } = Layout;

function MenuWrapper(props: { user: User }) {
  const { user } = props;

  return (
    <Layout style={{minHeight: '100vh'}}>
      <Header
        className="header"
        style={{
          backgroundColor: "#FAFAFA",
          display: "flex",
          minWidth: "0",
          justifyContent: "space-between",
          height: "85px",
        }}
      >
        <div>
          <img
            className="logo"
            src={logo}
            alt="blockapps logo"
            style={{ width: 225 }}
          />
        </div>
        <UserMenu user={user} />
      </Header>
      <Content>
        <Row>
          <Metadata />
          <TokenBalance user={user} />
        </Row>
        <Routes>
          <Route path={routes.HOME} element={<HomeView user={user} />} />
        </Routes>
      </Content>
      <Footer>
        <Space style={{margin: 'auto 2%', float: 'right'}} >
        <Typography.Text >
          Mercata STRATS is a loyalty program for BlockApps, Inc. and does not accept monetary deposits of any kind.
        </Typography.Text>
        <Divider type='vertical'/>
          <Typography.Link href="https://support.blockapps.net" target='_blank'>
            Support
          </Typography.Link>
        </Space>
      </Footer>
    </Layout>
  );
}
export default MenuWrapper;
