import React from 'react'
import { User } from '../types'
import { Avatar, Typography, Dropdown, Button } from 'antd'
import { UserOutlined } from '@ant-design/icons';
import { useAuthenticateState, useAuthenticateDispatch } from "../contexts/authentication/index";
import { actions } from "../contexts/authentication/actions"

function UserMenu(props: { user: User }) {

  const { user } = props

  const dispatch = useAuthenticateDispatch()

  const handleLogout = async () => {
    try {
      await actions.logout(dispatch);
    } catch (error) {
      console.log(error)
    }
  }
  const items = [
    {
      key: 'logout',
      label: (
        <Button type='text' onClick={handleLogout} danger block>
          Logout
        </Button>
      ),
    },
  ]
  
  const displayName = user.admin ? `${user.commonName} (ADMIN)` : `${user.commonName}`

  const balance = (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Avatar size="large" style={{ backgroundColor: '#FF3200' }} icon={<UserOutlined />} />
      <div style={{ marginLeft: 15, marginRight: 20 }}>
        <Typography.Title style={{ marginTop: '10px', marginBottom: '0px' }} level={4}>{displayName}</Typography.Title>
        <Typography.Title style={{ marginTop: '0px' }} level={5}>{`${user.organization}`}</Typography.Title>
      </div>
    </div>)

  return (
    <div>
      <Dropdown menu={{ items }}>
        {balance}
      </Dropdown>
    </div>
  )
}

export default UserMenu
