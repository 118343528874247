import React, { createContext, useContext, useReducer } from "react";
import reducer from "./reducer";

const ReceiptsStateContext = createContext();
const ReceiptsDispatchContext = createContext();

const ReceiptsProvider = ({ children }) => {
  const initialState = {
    receipts: [],
    isReceiptsLoading: false,
    receiptDetails: null,
    isReceiptDetailsLoading: false,
    error: undefined,
    success: false,
    message: null,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ReceiptsStateContext.Provider value={state}>
      <ReceiptsDispatchContext.Provider value={dispatch}>
        {children}
      </ReceiptsDispatchContext.Provider>
    </ReceiptsStateContext.Provider>
  )
}

const useReceiptsState = () => {
  const context = useContext(ReceiptsStateContext);
  if (context === undefined) {
    throw new Error(
      `'useReceiptsState' must be used within a ReceiptsProvider`
    );
  }
  return context;
}

const useReceiptsDispatch = () => {
  const context = useContext(ReceiptsDispatchContext);
  if (context === undefined) {
    throw new Error(
      `'useReceiptsDispatch' must be used within a ReceiptsProvider`
    );
  }
  return context;
};

const useReceipts = () => {
  return [useReceiptsState(), useReceiptsDispatch()];
}

export {
  useReceiptsState,
  useReceiptsDispatch,
  useReceipts,
  ReceiptsProvider
}
