import moment from 'moment';
import { User } from '../types/index'

export function getStringDate(time: any, format: any) {
  const timestamp = Number(time);
  if (Number.isNaN(timestamp)) {
    return '';
  }
  const adjustedTime = time < 1000000000000 ? time * 1000 : time;
  return moment(Number(adjustedTime)).format(format);
}

// Filters duplicate userAddresses from list of users
export const filterDuplicateUserAddresses = (arr: { label: string, value: string | undefined }[]) => {
  return [...new Map(arr.map((u: { label: string, value: string | undefined }) => [u.value, u])).values()];
}

// Filters organization to only "BlockApps" from list of users
export const filterUserOrg = (arr: User[]) => {
  let filteredList: User[] = []
  for (let i: number = 0; i < arr.length; i++) {
    if (arr[i].organization?.match(/blockapps/gi)) {
      filteredList.push(arr[i])
    }
  }
  return filteredList
}